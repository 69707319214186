<template>
  <v-layout
    style="
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -o-user-select: none;
      padding: 0;
    "
    fluid
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :headers="headers"
      :items="cus_data"
      :items-per-page="10"
      item-key="id"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="100%"
      fixed-header
      dense
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->

      <template v-slot:item="{ item }">
        <tr
          @click="row_click($event, item)"
          :class="{ selected_class: item.CusID === selectedId }"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->

          <td class="id_column">
            <span class="lime--text text--darken-3">{{ item.CusID }}.</span>
          </td>
          <td align="center">
            {{ item.CusName }}
          </td>
          <td align="end">
            {{ add_comma(parseInt(item.PriceSum) + parseInt(item.PriceLoy)) }}
          </td>
          <td class="text-end">
            {{ item.NonSendPage }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <PopupMenu ref="PopupMenu" />
  </v-layout>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import PopupMenu from "@/components/Customer/BetPage/Popup_Menu";

export default {
  name: "PagesTable",
  mixins: [globalFunctionMixin],
  components: {
    PopupMenu,
  },
  props: {
    tableheight: {
      type: Number,
      default: 500, //default v-data-table footer height
    },
  },
  mounted() {
    // console.log("1545");
    // this.ckLogin();
    // window.scrollTo(0, 0);
    // this.GetDataTable();
  },
  computed: {
    // //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    // bookie_data() {
    //   return this.$store.getters["bookie/Bookies"];
    // },
    // bookie_count() {
    //   return this.$store.getters["bookie/Bookies"].length;
    // },
    cus_data() {
      return this.$store.getters["cus/CusList"];
    },
  },
  methods: {
    async GetDataTable() {
      let parameter = {
        to: "Cus",
        method: "get_all_bet_data",
        PeriodDT: this.PeriodDT,
      };

      await this.$store.dispatch("cus/TransferData", parameter).then(
        (response) => {
          //   this.$router.push("/Dashboard");
        },
        (error) => {}
      );
    },
    GetStatusText(status) {
      const statusText = ["ระงับ", "ปกติ"];
      return statusText[parseInt(status)];
    },
    getStatusColor(status) {
      const statusColor = ["red--text", ""];
      return statusColor[parseInt(status)];
    },
    row_click: function (e, item) {
      if (this.selectedId == item.CusID) {
        this.popup_menu(e, item);
      }

      this.selectedId = item.CusID;
      this.$set(item, "selected", true);
    },
    popup_menu: function (e, item) {
      e.preventDefault();
      this.$refs.PopupMenu.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu.Show(item.CusID, item.Status);
        this.selectedId = item.CusID;
        //console.log(item);
      });
    },
  },
  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),

    selectedId: -1,
    PageNumber: 0,
    headers: [
      { text: "รหัส", align: "center" },
      { text: "ชื่อ", align: "center" },
      { text: "ยอดส่ง", align: "center" },
      { text: "โพยรอส่ง", align: "center" },
    ],
  }),
};
</script>

<style scoped>
.toolbar__items {
  flex-wrap: wrap;
}
::v-deep table {
  /* background-color: #ffffff; */
  /* table-layout: fixed; */
}
::v-deep th {
  font-size: 4vw !important;
  color: rgb(88, 88, 88);
  font-weight: bold;
}

::v-deep tr:nth-child(odd) {
  background-color: #fafafa;
}
::v-deep table tbody tr td {
  font-size: 4vw !important;
  color: rgb(88, 88, 88);
  font-weight: 500;
  border-right: 1px solid rgb(236, 236, 236);
  padding: 0 0;
}
.id_column {
  /* border-right: 1px solid rgb(190, 197, 255); */
  padding: 0 0;
}

.selected_class {
  background-color: pink !important;
  /* color: white !important; */
}
</style>
