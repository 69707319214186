<template>
  <div>
    <!-- <v-layout row style="background-color: white; flex-flow: row" class="py-2">
      <v-flex xs12 class="text-center">
        <span class="pink--text" style="font-weight: bold">จัดการงวด</span>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row"
      class="py-2"
    >
      <v-flex xs12 class="text-left pl-5">
        <v-icon color="pink accent-1">mdi-atom</v-icon>
        <span class="ml-3">สถานะ</span>
      </v-flex>
      <v-flex xs12 class="pr-3 text-right">ยังไม่เปิดให้ลูกค้าแทง</v-flex>
    </v-layout>
    <v-divider></v-divider> -->
    <v-layout row style="background-color: OldLace; flex-flow: row" class="py-2">
      <v-flex xs12 class="text-center">
        <span class="pink--text" style="font-weight: bold">ลูกค้า</span>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row"
      class="py-2"
    >
      <v-flex xs7 class="text-left pl-5"
        ><v-icon color="pink accent-1">mdi-atom</v-icon>
        <span class="ml-3">ลูกค้าทั้งหมด</span>
      </v-flex>
      <v-flex xs5 class="text-right"
        ><span class="pr-3">{{ CusAllCount }} คน</span></v-flex
      >
    </v-layout>
    <v-divider></v-divider>
    <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row"
      class="py-2"
    >
      <v-flex xs7 class="text-left pl-5"
        ><v-icon color="pink accent-1">mdi-atom</v-icon>
        <span class="ml-3">ปิดไม่ให้แทง</span>
      </v-flex>
      <v-flex xs5 class="text-right"
        ><span class="pr-3">{{ CusBanCount }} คน</span></v-flex
      >
    </v-layout>
    <v-divider></v-divider>
    <!-- <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row"
      class="py-2"
    >
      <v-flex xs7 class="text-left pl-5"
        >
      </v-flex>
      <v-flex xs5 class="text-right"><span class="pr-3">+ เพิ่มลูกค้า</span></v-flex>
    </v-layout>
    <v-divider></v-divider> -->
    <v-layout row style="background-color: OldLace; flex-flow: row" class="py-2">
      <v-flex xs12 class="text-center">
        <span class="pink--text" style="font-weight: bold">โพย</span>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row"
      class="py-2"
    >
      <v-flex xs7 class="text-left pl-5"
        ><v-icon color="pink accent-1">mdi-atom</v-icon>
        <span class="ml-3">ส่งแล้ว</span>
      </v-flex>
      <v-flex xs5 class="text-right"><span class="pr-3">{{SendPage}} ใบ</span></v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row"
      class="py-2"
    >
      <v-flex xs7 class="text-left pl-5"
        ><v-icon color="pink accent-1">mdi-atom</v-icon>
        <span class="ml-3">รอส่ง</span>
      </v-flex>
      <v-flex xs5 class="text-right"><span class="pr-3">{{NonSendPage}} ใบ</span></v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row"
      class="py-2"
    >
      <v-flex xs7 class="text-left pl-5"
        ><v-icon color="pink accent-1">mdi-atom</v-icon>
        <span class="ml-3">ตีกลับ</span>
      </v-flex>
      <v-flex xs5 class="text-right"><span class="pr-3">{{ReturnPage}} ใบ</span></v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-layout row style="background-color: OldLace; flex-flow: row" class="py-2">
      <v-flex xs12 class="text-center">
        <span class="pink--text" style="font-weight: bold">ยอดแทง</span>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row"
      class="py-2"
    >
      <v-flex xs7 class="text-left pl-5"
        ><v-icon color="pink accent-1">mdi-atom</v-icon>
        <span class="ml-3">ยอดรวม</span>
      </v-flex>
      <v-flex xs5 class="text-right"
        ><span class="pr-3">{{PriceSum}} บาท</span></v-flex
      >
    </v-layout>

    <v-divider></v-divider>
    <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row"
      class="py-2"
    >
      <v-flex xs7 class="text-left pl-5"
        ><v-icon color="pink accent-1">mdi-atom</v-icon>
        <span class="ml-3">ยอดลอย</span>
      </v-flex>
      <v-flex xs5 class="text-right"><span class="pr-3">{{PriceLoy}} บาท</span></v-flex>
    </v-layout>
    <v-divider></v-divider>

    <!-- <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row"
      class="py-1"
    >
      <v-btn
        id="menu-activator"
        color="blue lighten-2"
        dark
        @click="updateDataTableHeight()"
      >
        <v-icon left> mdi-percent-circle </v-icon>
        ดูเลขอั้น
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn color="pink lighten-2" dark>
        <v-icon left> mdi-plus-box </v-icon>
        เพิ่มโพยใหม่
      </v-btn>
    </v-layout>
    <v-divider></v-divider> -->
  </div>
</template>

<script>
export default {
  mounted() {
    this.$nextTick(() => {
      //console.log("mounted");
      //console.log(this.EditLine);
      this.GetDataTable();
      //this.table_focus();
    });
  },

  computed: {
    // //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    CusAllCount() {
      return this.$store.getters["cus/CusAllCount"];
    },
    CusBanCount() {
      return this.$store.getters["cus/CusBanCount"];
    },
    PriceSum() {
      return this.$store.getters["cus/PriceSum"];
    },
    PriceLoy() {
      return this.$store.getters["cus/PriceLoy"];
    },
    SendPage() {
      return this.$store.getters["cus/SendPage"];
    },
    NonSendPage(){
        return this.$store.getters["cus/NonSendPage"];
    },
    ReturnPage(){
        return this.$store.getters["cus/ReturnPage"];
    },
  },

  created() {
    //โหลด ตาราง
    console.log("Create Genaral");
  },

  methods: {
    async GetDataTable() {
      let parameter = {
        to: "Cus",
        method: "dashboard",
        PeriodDT: this.PeriodDT,
      };

      await this.$store.dispatch("cus/TransferData", parameter).then(
        (response) => {
          //   this.$router.push("/Dashboard");
          //   console.log(response);
          //   this.updateDataTableHeight();
        },
        (error) => {
          //   this.updateDataTableHeight();
          //   switch (error) {
          //     case "PIN_INCORRECT":
          //       this.$refs.pincodeInput.resetPincode();
          //       this.$alert("รหัสไม่ถูกต้อง.", "ผิดพลาด", "error").then(() => {});
          //       break;
          //     default:
          //       alert(error);
          //       break;
          //   }
        }
      );
    },
  },
  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),
  }),
};
</script>

<style></style>
