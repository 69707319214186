<template>
  <v-tabs v-model="active_tab" background-color="white" show-arrows dense>
    <v-tabs-slider color="red"></v-tabs-slider>

    <v-tab
      v-for="tab of tabs"
      :key="tab.id"
      @click="tab_change(tab.id)"
      class="pa-0 ma-0"
    >
      {{ tab.text }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "Menu_Tab",

  mounted() {
    this.$nextTick(() => {
      //   console.log("mounted");
      //   console.log(this.EditLine);
      if (typeof this.$route.query.Tab == "undefined") {
        console.log("undefined");
      } else {
        this.active_tab = this.$route.query.Tab-1;
        console.log(this.$route.query.Tab);
        this.tab_change(this.$route.query.Tab);
      }
    });

    // setTimeout(() => {
    //     if (typeof this.$route.query.Tab == "undefined") {
    //       console.log("undefined");
    //     } else {
    //       console.log(this.$route.query.Tab);
    //       this.tab_change(this.$route.query.Tab);
    //     }
    //   }, 500);
  },

  //   setTimeout(() => {
  //       if (typeof this.$route.query.Tab == "undefined") {
  //         console.log("undefined");
  //       } else {
  //         console.log(this.$route.query.Tab);
  //         this.onTabChange(this.$route.query.Tab);
  //       }
  //     }, 500);

  methods: {
    tab_change(id) {
      this.$emit("onTabChange", id);
    },
  },
  data: () => ({
    active_tab: 0,
    tabs: [
      { text: "ทั่วไป", id: 1 },
      { text: "รายชื่อ", id: 2 },
      { text: "โพย", id: 3 },
    //   { text: "บัญชี", id: 4 },
    ],
  }),
};
</script>

<style></style>
