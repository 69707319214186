<template>
  <v-dialog
    v-model="dialog"
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card>
      <v-toolbar flat dark color="pink lighten-1">
        <!-- <v-btn icon dark @click="dialog = false"> -->
        <v-btn icon dark>
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-toolbar-title>เพิ่มลูกค้า</v-toolbar-title>
        <!-- <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialog = false"> บันทึก </v-btn>
        </v-toolbar-items> -->
        <!-- <v-menu bottom right offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, i) in items" :key="i" @click="() => {}">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row dense class="mt-5">
            <v-col cols="12">
              <v-text-field
                v-model="Cus.Name"
                color="pink"
                label="ชื่อลูกค้า"
                ref="txtCusName"
                required
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-switch
                v-model="Cus.IsCredit"
                class="mt-0"
                color="primary"
                label="จำกัดวงเงิน"
              ></v-switch>
              <!-- :label="getStatusText()" -->
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="Cus.Credit"
                color="pink"
                label="วงเงินสูงสุดต่องวด"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-switch
                class="mt-0"
                color="primary"
                v-model="Cus.Status"
                label="เปิดใช้"
              ></v-switch>
              <!-- :label="getStatusText()" -->
            </v-col>
          </v-row>

          <!-- <v-col cols="12" sm="6">
              <v-autocomplete
                :items="[
                  'Skiing',
                  'Ice hockey',
                  'Soccer',
                  'Basketball',
                  'Hockey',
                  'Reading',
                  'Writing',
                  'Coding',
                  'Basejump',
                ]"
                label="Interests"
                multiple
              ></v-autocomplete>
            </v-col> -->
        </v-container>
        <!-- <small>*indicates required field</small> -->
        <!-- <v-btn color="primary" dark class="ma-2" @click="dialog2 = !dialog2">
          Open Dialog 2
        </v-btn>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ma-2" v-bind="attrs" v-on="on">
              Tool Tip Activator
            </v-btn>
          </template>
          Tool Tip
        </v-tooltip>
        <v-list three-line subheader>
          <v-subheader>User Controls</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Content filtering</v-list-item-title>
              <v-list-item-subtitle
                >Set the content filtering level to restrict apps that can be
                downloaded</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Password</v-list-item-title>
              <v-list-item-subtitle
                >Require password for purchase or use password to restrict
                purchase</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list three-line subheader>
          <v-subheader>General</v-subheader>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="notifications"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Notifications</v-list-item-title>
              <v-list-item-subtitle
                >Notify me about updates to apps or games that I
                downloaded</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="sound"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Sound</v-list-item-title>
              <v-list-item-subtitle
                >Auto-update apps at any time. Data charges may
                apply</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="widgets"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Auto-add widgets</v-list-item-title>
              <v-list-item-subtitle
                >Automatically add home screen widgets</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false"> ออก </v-btn>
        <v-btn color="blue darken-1" text @click="OnAddCus()"> บันทึก </v-btn>
      </v-card-actions>
      <!-- <div style="flex: 1 1 auto"></div> -->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Add_Customer",

  //   props: [
  //     "Status", //เปิดใช้งาน ระงับ
  //   ],

  methods: {
    Show() {
      this.dialog = true;
      this.Cus.Name = "";
      this.Cus.IsCredit = false;
      this.Cus.Credit = 0;
      this.Cus.Status = 1;
    },
    OnAddCus() {
      //check input
      this.Sending = true;
      this.doAddCus();
    },

    async doAddCus() {
      let parameter = {
        to: "Cus",
        method: "add",
        HostID: this.HostID,
        LineID: this.LineID,
        AgentID: this.AgentID,
        Name: this.Cus.Name,
        Credit: this.Cus.IsCredit == true ? this.Cus.Credit : -1,
        Status: this.Cus.Status,
      };

      await this.$store.dispatch("cus/TransferData", parameter).then(
        (response) => {
          //   this.$router.push("/Dashboard");
          this.dialog = false;
          this.Sending = false;
        },
        (error) => {
          this.Sending = false;
        }
      );
    },
  },
  data: () => ({
    dialog: false,
    Sending: false,

    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),

    Cus: {
      Name: "",
      IsCredit: false,
      Credit: 0,
      Status: 1,
    },
  }),
};
</script>

<style></style>
