<template>
  <v-flex xs12 class="row_title">
    <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row"
      class="py-2"
    >
      <v-flex xs12 class="text-left">
        <span class="pl-1">ไม่ให้แทง {{NotBetCus}} คน</span>
      </v-flex>
      <v-flex xs12 class="text-right">ทั้งหมด {{ AllCus }} คน</v-flex>
    </v-layout>
    <v-divider></v-divider>

    <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row"
      class="py-1"
    >
      <v-btn
        id="menu-activator"
        color="blue lighten-2"
        dark
        @click="updateDataTableHeight()"
      >
        <v-icon left> mdi-percent-circle </v-icon>
        ค้นหา
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn
        color="pink lighten-2"
        dark
        @click="OnAddCus()"
      >
        <v-icon left> mdi-plus-box </v-icon>
        เพิ่มลูกค้า
      </v-btn>
    </v-layout>
    <v-divider></v-divider>

    <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row"
      class="py-0"
    >
      <Table></Table>
      <Add_Customer ref="AddCusDlg" />
    </v-layout>
  </v-flex>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import Table from "@/components/Customer/Customer/Table_Customer";
import Add_Customer from "@/components/Customer/Customer/Add_Customer";

export default {
  name: "Pages_Main",
  mixins: [globalFunctionMixin],
  components: {
    Table,
    Add_Customer,
  },
  mounted() {
    this.$nextTick(() => {
      //console.log("mounted");
      //console.log(this.EditLine);
      this.GetDataTable();
      //this.table_focus();
    });
  },
  // mounted() {
    // this.ckLogin();
    // window.scrollTo(0, 0);
    // this.GetDataTable();

    // setTimeout(() => {
    //    this.updateDataTableHeight();
    // }, 1000);
  // },
  computed: {
    AllCus(){
        return this.$store.getters["cus/CusList"].length;
    },
    NotBetCus(){
        let c = 0;
        this.$store.getters["cus/CusList"].forEach(element => {
            if(element.Status == 0) c++;
        });
        return c ;
    },
    // PageSendCount() {
    //   //   console.log("update lines_data");
    //   return this.$store.getters["pages/PageSendCount"];
    // },
    // PriceSumAll() {
    //   return this.$store.getters["pages/PriceSumAll"];
    // },
    // PriceLoyAll() {
    //   return this.$store.getters["pages/PriceLoyAll"];
    // },
  },
  methods: {
    async GetDataTable() {
      let parameter = {
        to: "Cus",
        method: "get_all",
      };

      await this.$store.dispatch("cus/TransferData", parameter).then(
        (response) => {
          //   this.$router.push("/Dashboard");
        //   console.log(response);
        
          //   this.updateDataTableHeight();
        },
        (error) => {
          //   this.updateDataTableHeight();
          //   switch (error) {
          //     case "PIN_INCORRECT":
          //       this.$refs.pincodeInput.resetPincode();
          //       this.$alert("รหัสไม่ถูกต้อง.", "ผิดพลาด", "error").then(() => {});
          //       break;
          //     default:
          //       alert(error);
          //       break;
          //   }
        }
      );
    },
    updateDataTableHeight() {
      // console.log("test1");
      try {
        this.dataTableHeight = this.$refs.tableDiv.clientHeight;
        console.log(this.dataTableHeight);
      } catch (err) {
        // console.log("test2");
        console.log(err);
        // document.getElementById("demo").innerHTML = err.message;
      }
    },
    OnAddCus() {
      this.$refs.AddCusDlg.Show();
    },
    // GetAddPageColor() {
    //   if (this.PeriodIsOpen == 0) return "grey lighten-2";
    //   return "pink lighten-2";
    // },
  },
  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),

    CloseTime: "",
    PeriodStatus: 0,
    PeriodIsOpen: 0,

    WaitingSend_Count: 0,
    SendBack_Count: 0,
    Won_Count: 0,

    dataTableHeight: 0,

    Sending: false,
  }),
};
</script>

<style scoped>
.container {
  background-color: red;
  flex-grow: 1;
  flex-shrink: 0;
}
.layout {
  flex-flow: column;
  padding: 2px;
}
.row_title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: 100%;
  flex: 0 1;
}
.row_content {
  background-color: darkseagreen;
  flex: 3 0px;
  flex-grow: 1;
  flex-shrink: 0;
  overflow-y: auto;
}
.row_footer {
  background-color: rgb(245, 253, 255);
  flex: 0 1 40px;
  margin-bottom: 0;
  padding-bottom: 0;
  border-top: 2px solid LightBlue;
  color: MediumAquaMarine;
  font-size: 4vw;
  font-weight: bold;
  padding-top: 1% !important;
  padding-right: 5px !important;
  text-align: right;
}
.header_bg {
  background-color: whitesmoke;
  color: black;
  font-size: 4.4vw;
  padding-top: 0;
}

.line {
  height: 2px;
  width: 100%;
  background-color: black;
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickable {
  cursor: pointer;
}
</style>
