<template>
  <v-container fluid grid-list-xs class="container">
    <v-layout row wrap class="layout header_bg" fluid>
      <v-flex xs12 class="row_title">
        <v-layout
          row
          style="
            flex-flow: row;
            background-color: GhostWhite;
            padding-top: 10;
            font-weight: bold;
          "
          class="grey lighten-5"
        >
          <v-flex
            xs4
            class="text-left pl-3 pl-0 mb-2 mt-2"
            style="text-decoration: underline"
          >
            {{ getAbbrPeriodThai(PeriodDT) }}
            <v-icon color="pink lighten-1" class="ml-1"
              >mdi-arrow-down-drop-circle</v-icon
            >
          </v-flex>
          <v-flex xs8 class="text-right pr-3 mt-2 pink--text text--darken-1">
            จัดการลูกค้า</v-flex
          >
        </v-layout>
        <v-divider class="pink lighten-1"></v-divider>
        <v-layout
          row
          style="background-color: GhostWhite; flex-flow: row"
          class="pa-0 ma-0"
        >
          <v-flex xs12 class="text-left">
            <MenuTab @onTabChange="onTabChange" />
          </v-flex>
        </v-layout>
        <!-- <v-divider></v-divider> -->
      </v-flex>
      <v-flex xs12 class="row_content" ref="tableDiv">
        <component v-bind:is="component" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import MenuTab from "@/components/Customer/Main/Layout/Menu_Tab";

import GenaralPage from "@/components/Customer/Genaral/Main";
import CustomerPage from "@/components/Customer/Customer/Main_Customer";
import BetPagePage from "@/components/Customer/BetPage/Main_BetPage";
import BalancePage from "@/components/Customer/Balance/Main";

export default {
  name: "Line_Data",
  mixins: [globalFunctionMixin],
  components: {
    MenuTab,
    GenaralPage,
    CustomerPage,
    BetPagePage,
    BalancePage,
  },
  mounted() {
    // this.ckLogin();

    // this.component= "GenaralPage"
    // this.onTabChange(1);
    // this.bc_items[0].text = sessionStorage.getItem("host_id");
  },
  methods: {
    onTabChange(id) {
        console.log("id");
        console.log(id);
      switch (parseInt(id) ) {
        case 1:
          // console.log("123");
          this.component = GenaralPage;
          window.scrollTo(0, 0);
          break;
        case 2:
          this.component = CustomerPage;
          window.scrollTo(0, 0);
          break;
        case 3:
        console.log("id + 3");
          this.component = BetPagePage;
          window.scrollTo(0, 0);
          break;
        case 4:
          this.component = BalancePage;
          window.scrollTo(0, 0);
          break;
      }
      // alert(id)
    },
  },
  data: () => ({
    component: "GenaralPage",
    // { text: "ทั่วไป", id: 1 },
    //   { text: "รายชื่อ", id: 2 },
    //   { text: "โพย", id: 3 },
    //   { text: "บัญชี", id: 4 },
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),
  }),
};
</script>

<style></style>
